/*
* @Author: 曹俊杰
* @Date: 2023-01-10 17:04:13
* @Module: 用户认证
*/
<template>
  <div class="userAuth">
    <div class="top">
      <authStep :step="step" />
    </div>
    <div class="content">
      <div class="step1" v-show="step === 1">
        <div class="title">
          <i class="el-icon-ali-gerenshiming" style="color:#0062FF;margin-right:6px;"></i>
          <span>个人实名信息</span>
        </div>
        <div class="dsc">
          <p>应电子签名相关法律要求，请补充个人真实信息以确保签署者的真实身份</p>
          <p> 提高电子合同的安全性和法律效力，以及保护交易双方的权益</p>
         
        </div>
        <el-form ref="form" label-position="top" :model="form" label-width="84px" :rules="rules" size="medium">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
          </el-form-item>
          <el-form-item label="证件类型" prop="idType">
            <el-select v-model="form.idType" style="width: 100%;"  placeholder="选择您的证件类型">
              <el-option v-for="item in idType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-input v-model="form.representativeIdType" placeholder="选择您的法人代表证件类型"></el-input> -->
          </el-form-item>
          <el-form-item label="证件号" prop="idNumber">
            <el-input v-model="form.idNumber" placeholder="请输入您的证件号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入您的手机号"></el-input>
          </el-form-item>
        </el-form>

        <div class="btns">
          <div class="btns-l" @click="$router.go(-1)">返回</div>
          <div class="btns-r" @click="postAccountAuth">提交</div>
        </div>
      </div>

      <div class="step2" v-show="step === 2">
        <div class="title">
          <i class="el-icon-ali-xingzhuang1" style="color:#0062FF;margin-right:6px;"></i>
          <span>扫码进行认证</span>
        </div>
        <div class="dec">
          <span>请使用{{ form.name }}（{{ form.idNumber }}）的</span>
          <span style="color:#0062FF;">「支付宝账号」</span>
          <span>或</span>
          <span style="color:#0062FF;">「微信账号」</span>
          <span>账号扫码</span>
        </div>
        <vue-qr :text="authUrl" :size="300" style="margin-top:10px;"></vue-qr>
        <div class="btns" style="margin-top:40px">
          <div class="btns-l" @click="step = 1">上一步</div>
          <!-- <div class="btns-r">提交</div> -->
        </div>
      </div>
      <div class="step3" v-show="step === 3">
        <div class="title">
          <i class="el-icon-ali-xingzhuang1" style="color:#0062FF;margin-right:6px;"></i>
          <span>认证结果</span>
        </div>
        <el-result icon="success" title="认证成功">
        </el-result>
        <div class="btns" style="margin-top:40px">
          <div class="btns-l" @click="$router.push({ name: 'home' })">回到首页</div>
          <div class="btns-r" @click="$router.push({ name: 'CompanyAuth' })">企业认证</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import authStep from '@/components/authStep'
let js;
import vueQr from 'vue-qr'
import { mapState } from "vuex";
import selectData from "@/mixins/selectData.js"
export default {
  components: {
    authStep,
    vueQr
  },
  mixins: [selectData],
  data() {
    const phoneValidator = (_, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      step: 1,
      loading: false,
      form: {
        name: '',
        idNumber: '',
        idType: '',
        phone: ''
      },
      authUrl: '',
      rules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        idType: [{ required: true, message: '证件类型不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: phoneValidator, trigger: 'blur' }],
        idNumber: [
          { required: true, message: '证件号不能为空', trigger: 'blur' }
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted() {
    this.form.phone = this.user.phone;
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postAccountAuth()
        } else {
          return false;
        }
      });
    },
    showQR(data) {
      this.authUrl = data.authUrl
      // this.$refs.qrDialog.show(data.authUrl)
      this.step = 2;
      let js = setInterval(() => {
        this.$store.dispatch('user/getAccount', res => {
          if (res.name) {
            clearInterval(js);
            this.$store.dispatch('user/setUserInfo', res)
            this.showSuccess()
          }
        })
      }, 1000);
    },
    showSuccess() {
      this.step = 3;
    },
    async postAccountAuth() {
      this.loading = true
      const { status, info, data } = await this.$api.postAccountAuth(this.form);
      if (status == 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.showQR(data)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
      this.loading = false
    },
    close() {
      js && clearInterval(js);
    }
  },
};
</script>
<style lang='scss' scoped>
.userAuth {
  .top {
    background: #fff;
  }

  .content {
    padding-top: 30px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    padding-bottom: 60px;
    
    .title {
      text-align: center;

      color: #262626;
      font-family: "Source Han Sans CN";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
    }

    .dsc {
      color: #999;
      text-align: center;
      font-family: "Source Han Sans CN";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      /* 185.714% */
      margin-bottom: 30px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;

      &-l {
        width: 80px;
        line-height: 32px;

        background: #ffffff;
        border: 1px solid #e3e3e3;
        border-radius: 2px;
        margin-right: 14px;
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 14px;
        color: #262626;
        text-align: center;
        cursor: pointer;
      }

      &-r {
        width: 80px;
        line-height: 32px;
        background: #0d72ff;
        border-radius: 2px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }

    .step1 {
      width: 444px;
      margin: 0 auto;
    }

    .step2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      .dec {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #666666;
        margin-top: 19px;
      }
    }
  }
}
</style>